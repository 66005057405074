.pagination {
    position: absolute;
    bottom: 11px;
    display: flex;
    align-items: center;
    z-index: 999;
}

.pagination__dot {
    width: 14px;
    height: 14px;
    border: 1px solid #ffffff;
    cursor: pointer;
    border-radius: 100%;
    margin-left: 10px;
}

.pagination__dot:first-of-type {
    margin-left: 0;
}

.pagination__dot_active {
    background-color: #f6c944;
    border: 1px solid #f6c944;
}

.swiper-slide {
    width: 100% !important;
}